import React from 'react'
import Layout from "../components/layout"
import Project from '../components/project'
import SEO from "../components/seo"
import "./index.css"
import './skills.css'

const IndexPage = () => {

  /* useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 886) {
      window.addEventListener('load', changeContentDisposition(''))  
    }
  }, []) */

  const defaultButtonColor = '#337ab7'
  const activeButtonColor = '#31b0d5'  

  const filterCategories = (category) => {
    const buttons = document.getElementById('categories_buttons_container').getElementsByTagName('button')
    for (let button of buttons) {
      if (button.id === category) {
        button.style.backgroundColor = activeButtonColor
      } else {
        button.style.backgroundColor = defaultButtonColor
      }
    }
    const projects = document.getElementsByClassName('project-container')
    if (category === 'all') {
      for (let project of projects) {
        project.style.display = 'flex'
      }
    } else {
      for (let project of projects) {
        if (!project.classList.contains(category)) {
          project.style.display = 'none'
        } else {
          project.style.display = 'flex'
        }
      }
    }
    const main = document.getElementsByTagName('main')[0]
    main.dispatchEvent(new Event('resize', { 'bubbles': true }))
  }

  const changeContentDisposition = (type) => {
    const projectsCards = document.getElementsByClassName('project-container')  
    if (type === 'grid') {
      for (let p of projectsCards) {
        p.classList.add('smallCard')
      } 
    } else {
      for (let p of projectsCards) {
        p.classList.remove('smallCard')
      } 
    }
  }
  
  return (
  <Layout pageTitle="micpob.com">
    <SEO title="Home" />
    <h1 id="homepage_h1">Projects</h1>
    <br></br>
    <div id="show_content_options_container" style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
      <div id="categories_buttons_container" >
        <button id="all" style={{ backgroundColor: activeButtonColor }} onClick={ () => filterCategories('all') }>All</button>
        <button id="web_apps" onClick={ () => filterCategories('web_apps') }>Web</button>
        <button id="extensions" onClick={ () => filterCategories('extensions') }>Extensions</button>
        <button id="android" onClick={ () => filterCategories('android') }>Android</button>
        <button id="games" onClick={ () => filterCategories('games') }>Games</button>
        <button id="vba_apps" onClick={ () => filterCategories('vba_apps') }>VBA</button>
        {/* <button id="data" onClick={ () => filterCategories('data') }>Data</button> */}
      </div>
      <div id="content_disposition_buttons_container" /* style={{ marginLeft: 'auto' }} */>
        <button aria-label="Show projects in grid mode" id="grid" onClick={ () => changeContentDisposition('grid') } ></button>
        <button aria-label="Show projects in list mode" id="list" onClick={ () => changeContentDisposition('list') } ></button>
      </div>
    </div>
    <div id="projects_container">    
      <Project 
        title="Stack Explorer" 
        description="Android app to randomly explore questions and answers on the Stack Exchange network."
        categories="android"
        stack="React Native"
        details="projects/stack-explorer"
        live="https://play.google.com/store/apps/details?id=com.github.micpob.stackexplorer" 
        code="https://github.com/micpob/Stack-Explorer" 
        >        
      </Project>
      <Project 
        title="Bloglist App" 
        description="React app that shows a collaborative list of interesting blogs about software development."
        categories="web_apps"
        stack="React, CSS, NodeJs, MongoDB"
        details="projects/bloglist-app"
        live="https://www.micpob.com/bloglist-app/" 
        code="https://github.com/micpob/Bloglist-app"
        private> 
      </Project>
      <Project 
        title="Doremify" 
        description="A gamified single page application made to train notes finding on various instruments."
        categories="web_apps games"
        stack="HTML, CSS, JavaScript, PHP, SQL"
        details="projects/doremify"
        live="https://www.doremify.com" 
        code="https://github.com/micpob/Doremify" 
        private>        
      </Project>
      <Project 
        title="Asociación Comerciantes" 
        description="Website of a business owners association, made as a proof of concept for a course."
        categories="web_apps"
        stack="HTML, CSS, JavaScript, PHP, SQL"
        details="projects/asociacion-comerciantes"
        live="https://www.micpob.com/asociacion_comerciantes/" 
        code="https://github.com/micpob/Asociacion-Comerciantes" 
        private>
      </Project>
      <Project 
        title="Zenter" 
        description="Online game where you have to put a circle in the perfect center of a green canvas."
        categories="games web_apps"
        stack="HTML, CSS, JavaScript, NodeJs, MongoDB"
        details="projects/zenter"
        live="https://www.micpob.com/zenter/" 
        code="https://github.com/micpob/Zenter" 
        private>  
      </Project>
      <Project 
        title="Beautiful Words" 
        description="A visually powerful combination of beautiful pictures and interesting quotes."
        categories="web_apps"
        stack="HTML, CSS, JavaScript, NodeJs, Puppeteer"
        details="projects/beautiful-words"
        live="https://www.micpob.com/beautiful_words/" 
        code="https://github.com/micpob/Beautiful-Words"
        private> 
      </Project>
      <Project 
        title="Portfolio" 
        description="My portfolio page, built to show my projects and skills. You are actually here now!"
        categories="web_apps"
        stack="Gatsby, React, CSS, NodeJs"
        details="projects/portfolio"
        live="https://www.micpob.com" 
        code="https://github.com/micpob/Portfolio" 
        private>  
      </Project>
      <Project 
        title="Vinylizr for YouTube™" 
        description="This Chrome extension adds a vinyl sound background to YouTube videos."
        categories="extensions"
        stack="HTML, CSS, JavaScript"
        details="projects/vinylizr"
        live="https://chrome.google.com/webstore/detail/vinylizr-for-youtube/menanhkamjnihglgnockbffnjnngolfn" 
        code="https://github.com/micpob/Vinylizr" 
        private>
      </Project>
      <Project 
        title="Random Bookmark Machine" 
        description="A highly customizable Chrome extension that opens random URLs from your bookmarks."
        categories="extensions"
        stack="HTML, CSS, JavaScript"
        details="projects/random-bookmark-machine"
        live="https://chrome.google.com/webstore/detail/random-bookmark-machine/jpofhhlmbncfdodogngdidhkecjigaab"
        code="https://github.com/micpob/Random-Bookmark-Machine"
        > 
      </Project>
      <Project 
        title="Simple Counter Button" 
        description="A Chrome extension that adds a counter controlled by a button to your toolbar."
        categories="extensions"
        stack="HTML, CSS, JavaScript"
        details="projects/simple-counter-button"
        live="https://chrome.google.com/webstore/detail/simple-counter-button/ganimlbiphepcnkneheoamhohhmjpohp"
        code="https://github.com/micpob/Simple-Counter-Button"> 
      </Project>
      <Project 
        title="Copy on Select" 
        description="This Chrome extension automatically copies everything you select to the clipboard."
        categories="extensions"
        stack="HTML, CSS, JavaScript"
        details="projects/copy-on-select"
        live="https://chrome.google.com/webstore/detail/copy-on-select/kdfngfkkopeoejecmfejlcpblohnbael" 
        code="https://github.com/micpob/Copy-On-Select" 
        private>
      </Project>
      <Project 
        title="Pop Them All" 
        description="A colorful and easy-going game for Android. See those balloons? You have to pop them all!"
        categories="games android"
        stack="MIT App Inventor"
        details="projects/pop-them-all"
        live="https://play.google.com/store/apps/details?id=com.bombolo_app.Pop_Them_All"
        code="https://github.com/micpob/Pop-Them-All" 
        private> 
      </Project>
      <Project 
        title="Bombolo App" 
        description="Android app made as a tribute to the Italian comedian Franco Lechner, AKA Bombolo."
        categories="android"
        stack="MIT App Inventor"
        details="projects/bombolo-app"
        code="https://github.com/micpob/Bombolo-App" > 
      </Project>
      <Project 
        title="VBA Code Obfuscator" 
        description="A VBA app that parses Excel files and makes VBA code unintelligible to the human eye."
        categories="vba_apps"
        stack="VBA (Visual Basic for Applications)"
        details="projects/vba-code-obfuscator"
        code="https://github.com/micpob/VBA-Code-Obfuscator"
        private > 
      </Project>
      <Project 
        title="Video QA App" 
        description="This application was created to improve the work of a team of audiovisual QA analysts."
        categories="vba_apps"
        stack="VBA (Visual Basic for Applications)"
        details="projects/video-qa-app"
        code="https://github.com/micpob/Video-QA-App"
        private > 
      </Project>
    </div>
  </Layout>
  )
}
export default IndexPage
